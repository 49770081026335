import { getAccessToken } from "../../auth/verifyAuth";

async function register(email, name, surname) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/register", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({ email: email, name: name, surname: surname })
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function deleteUser(email) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/removeUser", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: email
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function updateRights(listUser) {
    const accessToken = await getAccessToken();

    if (listUser.length) {
        return fetch(window.location.origin + "/api/v1.0/cosmos/updateRights", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "my-Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify(listUser)
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
        }).then(res => {
            return res;
        })
    }
}

async function updateDefaultRights(id, rights) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/updateDefaultRights", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id+"&"+JSON.stringify(rights)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getUsers() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/getUsers", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getCurentUser() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/getCurentUser", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function tenantData() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/tenantData", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            res = JSON.parse(res)
        }
        return res;
    })
}

async function tenantLongData() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/tenantLongData", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            res = JSON.parse(res)
        }
        return res;
    })
}

async function newForm(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CreateForm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: partitionKey
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function updateForm(form) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(formCpy.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateForm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    }).then(res => {
        if (res.ok) {
            return form;
        }
    })
}

async function updateInfoForm(form) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(formCpy.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateInfoForm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    }).then(res => {
        if (res.ok) {
            return form;
        }
    })
}

async function updateDataForm(form) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(form.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateDataForm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function updateDesignerSteps(form) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // if (typeof (form.Data) == "string") {
    //     formCpy.Data = JSON.parse(form.Data);
    // }
    // formCpy.Data = JSON.parse(JSON.stringify(form.Data));
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) != "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.parse(ee.questions)
    //     }

    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateDesignerSteps", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function moveDesignerSteps(oldPosition, currPos, formId) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/MoveDesignerSteps", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: oldPosition + ":" + currPos + ":" + formId
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            return JSON.parse(res);
        }
    })
}

async function updateFormStatus(form) {
    const accessToken = await getAccessToken();
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateFormStatus", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function formData(id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetForm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        let jsonRes = JSON.parse(res)
        if (typeof (jsonRes.Data) == "string")
            jsonRes.Data = JSON.parse(jsonRes.Data)
        if (typeof (jsonRes.Step[0].questions) == "string") {
            jsonRes.Step.forEach((ee, ii) => {
                jsonRes.Step[ii].questions = JSON.parse(ee.questions)
            })
        }
        return jsonRes;
    })
}

async function updateTheme(theme, id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetTheme", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(theme) + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function getHubspotKey() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKey", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getHubspotKeyFO() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKeyFO", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function updateHubspotKey(hubsspotKey, id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetHubspotKey", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (hubsspotKey + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotKeyFO(hubsspotKey, id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetHubspotKeyFO", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (hubsspotKey + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeleton(id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeleton", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeletonById(id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeletonById", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeletonForced() {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeletonForced", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotPipeline(id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/pipeline", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateInformationList(infoList) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/informationList", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(infoList))
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateInformationListGet() {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/informationList", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function getHubspotSkeleton() {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/getSkeleton", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function GenerateLightForms() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GenerateLightForms", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getPaymentKey(position) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetPaymentKey", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (position)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function updatePaymentKey(obj, position) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetPaymentKey", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(obj) + "&" + position)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function addPayment(type) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/AddPayment", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (type)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function removePayment(position) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/RemovePayment", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (position)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function CheckPipelineCompatibility() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckPipelineCompatibility", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function CheckSchemaCompatibility() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckSchemaCompatibility", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function uploadFile(file) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/file", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (file)
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error();
        }
    })
}

async function getAllTenants() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/GetAllTenants", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        else {
            return ""
        }
    }).then(res => {
        return res.split(',');
    })
}

async function getCurentTenant() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/curentTenantData", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        else {
            return ""
        }
    }).then(res => {
        return res;
    })
}

async function switchCurentUserTenant(id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/SwitchCurentUserTenant", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function createNewClient(name) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/CreateDb", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: name
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function getFormTypes() {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/formTypes", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function CheckUnicityConflict(id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckUnicityConflict", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res)
    })
}

async function CheckUnicityConflictError() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckUnicityConflict", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            return JSON.parse(res)
        }
    })
}

async function UpdateFormVariables(variables, id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateFormVariables/" + id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(variables)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function LogDesignerError(error, id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/LogDesignerError/" + id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(error)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

export {
    register,
    deleteUser,
    updateRights,
    updateDefaultRights,
    getUsers,
    getCurentUser,
    tenantData,
    tenantLongData,
    newForm,
    formData,
    updateForm,
    updateInfoForm,
    updateDataForm,
    updateDesignerSteps,
    moveDesignerSteps,
    updateTheme,
    getHubspotKey,
    getHubspotKeyFO,
    updateHubspotKey,
    updateHubspotKeyFO,
    updateHubspotSkeleton,
    updateHubspotSkeletonById,
    updateHubspotSkeletonForced,
    updateHubspotPipeline,
    updateInformationList,
    updateInformationListGet,
    getPaymentKey,
    updatePaymentKey,
    addPayment,
    removePayment,
    getHubspotSkeleton,
    GenerateLightForms,
    updateFormStatus,
    CheckPipelineCompatibility,
    CheckSchemaCompatibility,
    uploadFile,
    getAllTenants,
    getCurentTenant,
    switchCurentUserTenant,
    createNewClient,
    getFormTypes,
    CheckUnicityConflict,
    CheckUnicityConflictError,
    UpdateFormVariables,
    LogDesignerError
}