import { ChevronLeftIcon, ChevronRightIcon, CodeIcon, PlusIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import SimpleSelect from "../../../../components/Blank/SimpleSelect";
import Card from "../../../../components/Card/Card";
import Input from "../../../../components/Inputs/Input";
import Switch from "../../../../components/Inputs/Switch";
import MultipleSelect from '../../../../components/Blank/MultipleSelect';
import { Tooltip } from "react-tooltip";
import Banner from "../../../Theme/Component/Banner";
import JsonPopup from "../../../../components/Popup/jsonPopup";
import { toast } from "react-toastify";
import { set } from "react-hook-form";

const conditionsLabels = {
    "select": [
        "contains any of", "contains none of", "is not empty"
    ],
    "multi": [
        "is equal to", "is not equal to", "contains any of", "contains none of", "contains all of", "doesn't contain all of", "is not empty"
    ],
    "date": [
        "is equal to", "is less than", "is greater than", "is between", "is not between", "was more than", "was less than", "is not empty"
    ],
    "booleancheckbox": [
        "is equal to", "is not empty"
    ],
    "number": [
        "is equal to", "is not equal to", "is less than", "is less than or equal to", "is greater than", "is greater than or equal to", "is between", "is not between", "is not empty"
    ],
    "file": [
        "is not empty"
    ],
    "misc": [
        "is equal to", "is not equal to", "contains", "doesn't contain", "start with", "ends with", "is not empty"
    ]
}

const errorTranslation = {
    "question" : "This object doesn't exist on hubspot anymore",
    "conditional" : "A condition doesn't work anymore",
    "option" : "A custom option has been removed on hubspot"
}

function Details({ t, components, id, handleOnChange, onClose, setRefresh, setSelectedSubs, fromLogic, currStep }) {
    const [option, setOption] = useState({});
    const [details, setDetails] = useState(null);
    const [conditional, setConditional] = useState(false);
    const [readonly, setReadonly] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [required, setRequired] = useState(false);
    const [optionsCustom, setOptionsCustom] = useState(false);
    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [subs, setSubs] = useState([])
    const [newRule, setNewRule] = useState(false)
    const [conditionList, setConditionList] = useState([]) //[{ "select": "contains any of", "cond": "condition", "subs": ["steps"], And: conditionList[x] }] (And if And is selected)
    const [ifCondition, setIfCondition] = useState("")
    const [ifComparaisonTxt, setIfComparaisonTxt] = useState("")
    const [ifComparaison, setIfComparaison] = useState([])
    const [ifResult, setIfResult] = useState([])
    const [ifBlock, setIfBlock] = useState("")
    const [oldCondition, setOldCondition] = useState(null)
    const [resultHover, setResultHover] = useState("")
    const [dataSaveState, setDataSaveState] = useState("")
    const [ifAnd, setIfAnd] = useState(-1)
    const [curentCondition, setCurentCondition] = useState({}) //{ "select": "contains any of", "cond": "condition", "subs": ["steps"], And: conditionList[x] }] (And if And is selected)
    const [justCanceld, setJustCanceld] = useState(false)
    const [compError, setCompError] = useState([])
    const [firstCond, setFirstCond] = useState(true)
    const [jsonDisplay, setJsonDisplay] = useState(false)
    const [localStep, setLocalStep] = useState(-1)

    //TODO gestion ereur else seul

    useEffect(() => {
        if (components && id && components[id]) {
            setJsonDisplay(false)
            if (components[id]?.data?.conditional) {
                setFirstCond(false)
            } else {
                setFirstCond(true)
            }
            setDetails(null)
            setIfAnd(-1)
            setOption(components[id])
            if (components[id].data.error) {
                setCompError(components[id].data.errorList.map(err=> {
                    if (err in errorTranslation) {
                        return errorTranslation[err]
                    } return err
                }))
            }
            if (dataSaveState) {
                components[JSON.parse(dataSaveState).id].data = JSON.parse(dataSaveState)
            }
            setDataSaveState(JSON.stringify(components[id].data))
            if (components[id].data?.optionsCustomList && components[id].data?.optionsCustom) {
                setDisplayedOptions(components[id].data.optionsCustomList)
                setOptionsCustom(components[id].data.optionsCustom)
            } else {
                setDisplayedOptions(components[id].data.options?.map(o => o.value))
                setOptionsCustom(false)
            }
            var tmpSubs = []
            for (const [key, value] of Object.entries(components)) {
                if (value.data.sub === id) {
                    tmpSubs.push(key)
                }
            }
            setReadonly(components[id].data.readOnly)
            setHidden(components[id].data.hidden)
            setRequired(components[id].data.required)
            setSubs(tmpSubs)
            ifCleanup()
            if (components[id].data.conditionalList) {
                let localConditionList = components[id].data.conditionalList
                localConditionList.forEach(e => {//TODO check si ca pose problème
                    console.log(e)
                    if (e.cond.length === 1 && !e.select.includes("between") && components[id].data.fieldType === "string" && !e.block) {
                        e.cond = e.cond[0]
                    }
                    // } else if (e.cond.length === 1 && !e.select.includes("between") && e.block && components[e.block].data.fieldType !== "select" && components[e.block].data.fieldType !== "multi") {
                    //     e.cond = e.cond[0]
                    // }
                    for (let cond = e; cond.And; cond = cond.And) {
                        if (cond.cond.length === 1 && !cond.select.includes("between") && !e.block && components[id].data.fieldType === "string") {
                            cond.cond = cond.cond[0]
                        }
                        // else if (cond.cond.length === 1 && !cond.select.includes("between") && cond.block && components[cond.block].data.fieldType !== "select" && components[cond.block].data.fieldType !== "multi") {
                        //     cond.cond = cond.cond[0]
                        // }
                    }
                })
                setConditionList(JSON.parse(JSON.stringify(localConditionList)))
            } else {
                setConditionList([])
            }
            setNewRule(false)
        }
        else {
            setOption(null)
            setSubs([])
            setDataSaveState("")
        }
    }, [components, id]);

    useEffect(() => {
        if (currStep !== null && currStep !== undefined) {
            if (currStep !== localStep) {
                setLocalStep(currStep)
                if (id) {
                    onCancel()
                }
            }
        }
    }, [currStep, id])

    useEffect(() => {
        if (option) {
            if (option.data && option.data.conditional !== undefined) {
                setConditional(option.data.conditional)
            }
            setDetails({ name: option.data ? option.data.label : option.content, description: option.data ? option.data.description : null, tooltip: option.data ? option.data.tooltip : null, conditional: option.data ? option.data.conditional : false, conditions: [], conditionTmp: { select: "equal", cond: "", step: "" } })
        }
    }, [option])

    useEffect(() => {
        if (resultHover) {
            setSelectedSubs(resultHover) //focus on hovered item
            // setSelectedSubs(resultHover + ifResult) //focus on selected items
        } else {
            setSelectedSubs(ifResult)
        }
    }, [resultHover])

    useEffect(() => {
        if (components && id && components[id] && fromLogic && fromLogic !== true && conditionList && conditionList.length !== 0 && !justCanceld) {
            if (oldCondition && fromLogic >= oldCondition.position) {
                loadCondition(fromLogic - 1)
            } else {
                loadCondition(fromLogic)
            }
        } if (!justCanceld) {
            setJustCanceld(false)
        }
    }, [fromLogic, conditionList])

    useEffect(() => {
        if (ifComparaison && typeof ifComparaison !== typeof []) {
            setIfComparaison([ifComparaison])
        }
    }, [ifComparaison])

    // useEffect(() => {
    //     console.log(conditionList)
    // }, [conditionList])

    // function checkSubConection(componant, sub, componantList) {
    //     if (typeof componant.data.sub !== "string" || componant.data.sub.length === 0) {
    //         return false
    //     } else if (componant.data.sub !== sub) {
    //         return checkSubConection(components[componant.data.sub], sub, componantList)
    //     }
    //     const prevComp = components[componantList[componantList.indexOf(componant.data.id) - 1]]
    //     if (prevComp.data.id === sub) {
    //         return true
    //     } else if (prevComp.data.sub) {
    //         return checkSubConection(prevComp, sub, componantList)
    //     }
    //     return false
    // }

    function errorCleanup(condition, id) {
        let subList = []
        let output = condition
        condition.subs.forEach((sub) => {
            if (components[sub]?.data?.sub === id) {
                subList.push(sub)
            }
        })
        if (subList.length > 0) {
            output.subs = subList
            return output
        }
        return null
    }

    function optionSave() {
        var localConditionList = conditionList.map(condition => {
            let subOutput = errorCleanup(condition, id)
            if (subOutput) {
                condition.subs = subOutput.subs
                return condition
            }
        }
        ).filter(v => v !== undefined)
        if (newRule) {
            if (ifCondition && (ifComparaison || ifComparaisonTxt) && ifResult) {
                if (curentCondition && Object.keys(curentCondition).length !== 0) {
                    localConditionList = curentCondition
                    let x = 0
                    for (let conditions = localConditionList; conditions.And; conditions = conditions.And) {
                        if (x === ifAnd) {
                            setIfAnd(cunrentIfAnd => cunrentIfAnd + 1)
                            let ifCompLoco = ""
                            if (ifCondition === "is not empty" || ifCondition === "else") {
                                ifCompLoco = ""
                            } else {
                                if (ifComparaison && ifComparaison.length > 0) {
                                    ifCompLoco = ifComparaison
                                } else {
                                    ifCompLoco = ifComparaisonTxt
                                }
                            }
                            conditions.select = ifCondition
                            conditions.cond = ifCompLoco
                            conditions.subs = ifResult
                            if (ifBlock !== null || ifBlock !== "") {
                                conditions.block = ifBlock
                            }
                        }
                        if (!conditions.select || ((conditions.select !== "is not empty" && ifCondition !== "else") && !conditions.cond) || !conditions.subs) {
                            return
                        }
                        x += 1
                    }
                    if (localConditionList.length) {
                        if (oldCondition !== null && oldCondition.position !== null) {
                            localConditionList.toSpliced(oldCondition.position, 0, localConditionList)
                        } else {
                            localConditionList.concat([localConditionList])
                        }
                    }
                    else {
                        localConditionList = [localConditionList]
                    }
                } else {
                    let ifCompLoco = ""
                    if (ifCondition === "is not empty" || ifCondition === "else") {
                        ifCompLoco = ""
                    } else {
                        if (ifComparaison && ifComparaison.length > 0) {
                            ifCompLoco = ifComparaison
                        } else {
                            ifCompLoco = ifComparaisonTxt
                        }
                    }
                    let outputJson = { "select": ifCondition, "cond": ifCompLoco, "subs": ifResult }
                    if (ifBlock !== null || ifBlock !== "") {
                        outputJson.block = ifBlock
                    }
                    if (localConditionList.length) {
                        if (oldCondition !== null && oldCondition.position !== null) {
                            localConditionList.toSpliced(oldCondition.position, 0, outputJson)
                        } else {
                            localConditionList.concat([outputJson])
                        }
                    }
                    else {
                        localConditionList = [outputJson]
                    }
                }
            }
        }
        // localConditionList = localConditionList.filter(v => v !== undefined)
        // let localConditionList = conditionList
        localConditionList.forEach(e => {
            if (typeof e.cond === typeof "") {
                e.cond = [e.cond]
            }
            for (let cond = e; cond.And; cond = cond.And) {
                if (typeof cond.cond === typeof "") {
                    cond.cond = [cond.cond]
                }
            }
        })
        if (!conditional) {
            localConditionList = null
            Object.keys(components).forEach(e => {
                if (components[e].data.sub === id) {
                    if (option.data.sub) {
                        components[components[e].id].data.sub = option.data.sub
                    } else {
                        components[components[e].id].data.sub = ""
                    }
                }
            })
        }
        var localDisplayOptions = []
        if (optionsCustom) {
            localDisplayOptions = displayedOptions
        }
        handleOnChange({
            id: option.id,
            label: details.label ? details.label : details.name,
            description: details.description,
            tooltip: details.tooltip,
            conditional: conditional,
            conditionalList: localConditionList,
            readOnly: readonly,
            hidden: hidden,
            required: required,
            optionsCustom: optionsCustom,
            optionsCustomList: localDisplayOptions
        });
        ifCleanup()
        setDataSaveState("")
        setRefresh((refresh) => ((refresh + 1) % 5) + 1)
    }

    function onCancel() {
        for (const [key, value] of Object.entries(components)) {
            if (value.data.sub === id && !subs.includes(key)) {
                components[key].data.sub = ""
            }
            if (subs.includes(key)) {
                components[key].data.sub = id
            }
        }
        components[id].data = JSON.parse(dataSaveState)
        setDataSaveState("")
        ifCleanup()
        setConditionList([])
        setOldCondition(null)
        setRefresh((refresh) => ((refresh + 1) % 5) + 1)
        onClose()
    }

    function getRender(detail) {
        if (detail) {
            return <>
                {compError && compError.map(error => <Banner text={error} description={"reload hubspot schema when done"}/> )}
                <div>{t("Label")}</div>
                <Input type="text" defaultValue={detail.name} onChange={e => setDetails(details => ({ ...details, name: e.target.value }))} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
                <div className="pt-2">{t("Description")}</div>
                <Input type="text" defaultValue={detail.description} onChange={e => setDetails(details => ({ ...details, description: e.target.value }))} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
                <div className="pt-2">{t("Tips")}</div>
                <Input type="text" defaultValue={detail.tooltip} onChange={e => setDetails(details => ({ ...details, tooltip: e.target.value }))} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
            </>;
        }
    }

    // useEffect(() => {
    //     if (ifCondition && option && option.data && option.data.fieldType) {
    //         if (option.data.fieldType !== "file" && ifCondition !== "is not empty" && ifCondition !== "else" && !ifCondition.includes("between")) {
    //             switch (option.data.fieldType) {
    //                 case "select":
    //                 case "multi":
    //                     setIfComparaisonTxt("")
    //                     break
    //                 default:
    //                     setIfComparaison([])
    //             }
    //         } else if (ifCondition === "is between" || ifCondition === "is not between") {
    //             setIfComparaisonTxt("")
    //             if (ifComparaison.length !== 2) {
    //                 setIfComparaison(["", ""])
    //             }
    //         } else {
    //             setIfComparaisonTxt("")
    //             setIfComparaison([])
    //         }
    //     }
    // }, [ifCondition])

    function ifConditionCleanup(localCond) {
        if (!localCond) {
            localCond = ifCondition
        }
        if (localCond && option && option.data && option.data.fieldType) {
            if (option.data.fieldType !== "file" && localCond !== "is not empty" && localCond !== "else" && !localCond.includes("between")) {
                switch (option.data.fieldType) {
                    case "select":
                    case "multi":
                        setIfComparaisonTxt("")
                        break
                    default:
                        setIfComparaison([])
                }
            } else if (localCond === "is between" || localCond === "is not between") {
                setIfComparaisonTxt("")
                if (ifComparaison.length !== 2) {
                    setIfComparaison(["", ""])
                }
            } else {
                setIfComparaisonTxt("")
                setIfComparaison([])
            }
        }
    }

    function getComparaisonRend(local_option) {
        if (local_option && local_option.data && local_option.data.fieldType) {
            if (local_option.data.fieldType !== "file" && ifCondition !== "is not empty" && ifCondition !== "else") {
                switch (local_option.data.fieldType) {
                    case "select":
                    case "multi":
                        return (<MultipleSelect setValue={setIfComparaison} value={ifComparaison} labels={local_option.data.options.map(x => x.label)}
                            options={local_option.data.options.map(x => x.value)}
                        />)
                    case "booleancheckbox":
                        return (<SimpleSelect setValue={setIfComparaison} value={typeof ifComparaison === typeof [] ? ifComparaison[0] : ifComparaison} labels={local_option.data.options.map(x => x.label)}
                            options={local_option.data.options.map(x => x.value)}
                        />)
                    case "date":
                        if ((ifCondition === "is between" || ifCondition === "is not between") && ifComparaison.length === 2) {
                            return (<div className="grid grid-cols-2">
                                <input defaultValue={ifComparaison[0]} onChange={e => setIfComparaison(comp => { comp[0] = e.target.value; return comp })} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="date" />
                                <input defaultValue={ifComparaison[1]} onChange={e => setIfComparaison(comp => { comp[1] = e.target.value; return comp })} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="date" />
                            </div>)
                        } else {
                            return (<input value={ifComparaisonTxt} onChange={e => setIfComparaisonTxt(e.target.value)} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="date" />)
                        }
                    default:
                        if ((ifCondition === "is between" || ifCondition === "is not between") && ifComparaison.length === 2) {
                            return (<div className="grid grid-cols-2">
                                <input defaultValue={ifComparaison[0]} onChange={e => setIfComparaison(comp => { comp[0] = e.target.value; return comp })} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"></input>
                                <input defaultValue={ifComparaison[1]} onChange={e => setIfComparaison(comp => { comp[1] = e.target.value; return comp })} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"></input>
                            </div>)
                        } else {
                            return (<input value={ifComparaisonTxt} onChange={e => setIfComparaisonTxt(e.target.value)} className="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"></input>)
                        }
                }
            }
        }
    }

    function ifCleanup() {
        setIfComparaison([])
        setIfComparaisonTxt("")
        setIfCondition("")
        ifConditionCleanup("")
        setIfResult([])
        setSelectedSubs([])
        setIfBlock("")
        setIfAnd(-1)
        setCurentCondition({})
    }

    function ifSave(save = true) { //if curentCondition save curent data on ifAnd then setConditionlist(...curentCondition)
        // console.log("ifSave")
        // console.log(ifComparaison)
        // console.log(ifComparaisonTxt)
        // console.log(ifResult)
        // console.log(curentCondition)
        if (ifCondition && (ifComparaison || ifComparaisonTxt) && ifResult) {
            if (curentCondition && Object.keys(curentCondition).length !== 0) {
                // console.log(1)
                let localConditionList = curentCondition
                let x = 0
                for (let conditions = localConditionList; conditions.And; conditions = conditions.And) {
                    // console.log(conditions)
                    if (x === ifAnd) {
                        setIfAnd(cunrentIfAnd => cunrentIfAnd + 1)
                        let ifCompLoco = ""
                        if (ifCondition === "is not empty" || ifCondition === "else") {
                            ifCompLoco = ""
                        } else {
                            if (ifComparaison && ifComparaison.length > 0) {
                                ifCompLoco = ifComparaison
                            } else {
                                ifCompLoco = ifComparaisonTxt
                            }
                        }
                        conditions.select = ifCondition
                        // console.log(ifCompLoco)
                        conditions.cond = ifCompLoco
                        conditions.subs = ifResult
                        if (ifBlock !== null || ifBlock !== "") {
                            conditions.block = ifBlock
                        }
                    }
                    if (!conditions.select || ((conditions.select !== "is not empty" && ifCondition !== "else") && !conditions.cond) || !conditions.subs) {
                        // console.log("err")
                        return
                    }
                    x += 1
                }
                // console.log(localConditionList)
                if (oldCondition !== null && oldCondition.position !== null) {
                    console.log("1.1")
                    setConditionList(list => list.toSpliced(oldCondition.position, 0, localConditionList))
                } else {
                    console.log("1.2")
                    setConditionList(list => list.concat([localConditionList]))
                }
                setCurentCondition({})
                setIfAnd(-1)
            } else {
                // console.log(2)
                // console.log(ifCondition)
                let ifCompLoco = ""
                if (ifCondition === "is not empty" || ifCondition === "else") {
                    ifCompLoco = ""
                } else {
                    if (ifComparaison && ifComparaison.length > 0) {
                        ifCompLoco = ifComparaison
                    } else {
                        ifCompLoco = ifComparaisonTxt
                    }
                }
                let outputJson = { "select": ifCondition, "cond": ifCompLoco, "subs": ifResult }
                if (ifBlock !== null || ifBlock !== "") {
                    outputJson.block = ifBlock
                }
                // console.log(outputJson)
                if (oldCondition !== null && oldCondition.position !== null) {
                    console.log("2.1")
                    setConditionList(list => list.toSpliced(oldCondition.position, 0, outputJson))
                } else {
                    console.log("2.2")
                    setConditionList(list => list.concat([outputJson]))
                }
            }
            if (save) {
                setOldCondition(null)
                ifCleanup()
                setNewRule(false)
            }
        }
    }

    function loadCondition(position) {
        if (oldCondition) {
            const oldPosition = oldCondition.position
            delete oldCondition.position
            conditionList.splice(oldPosition, 0, oldCondition)
            if (position >= oldPosition) {
                position++
            }
        }
        const condLcl = conditionList[position]
        condLcl.position = position
        setOldCondition(JSON.parse(JSON.stringify(condLcl)))
        conditionList.splice(position, 1);
        setIfBlock(condLcl.block)
        let fieldType = ""
        if (condLcl.block) {
            fieldType = components[condLcl.block].data.fieldType
        } else {
            fieldType = option.data.fieldType
        }
        console.log(condLcl)
        console.log(components[condLcl.block])
        if (typeof condLcl.cond === typeof [] && fieldType !== "text") {
            setIfComparaison(condLcl.cond)
            setIfComparaisonTxt("")
        } else {
            setIfComparaisonTxt(condLcl.cond)
            setIfComparaison([condLcl.cond])
        }
        setIfResult(condLcl.subs)
        setSelectedSubs(condLcl.subs)
        setIfCondition(condLcl.select)
        if (condLcl.And) {
            setIfAnd(0)
            setCurentCondition(condLcl)
        } else {
            setIfAnd(-1)
        }
        setNewRule(true)
    }

    function preIfResult(data) {
        setIfResult(data)
        setSelectedSubs(data)
    }

    // function saveCUrentAnd() {
    //     let localConditionList = curentCondition
    //     let x = 0
    //     for (let conditions = localConditionList; conditions.And; conditions = conditions.And) {
    //         if (x === ifAnd) {
    //             setIfAnd(ifAnd + 1)
    //             let ifCompLoco = ""
    //             if (ifCondition === "is not empty" || ifCondition === "else") {
    //                 ifCompLoco = ""
    //             } else {
    //                 if (ifComparaison && ifComparaison.length > 0) {
    //                     ifCompLoco = ifComparaison
    //                 } else {
    //                     ifCompLoco = ifComparaisonTxt
    //                 }
    //             }
    //             conditions.select = ifCondition
    //             conditions.cond = ifCompLoco
    //             conditions.subs = ifResult
    //             if (ifBlock !== null || ifBlock !== "") {
    //                 conditions.block = ifBlock
    //             }
    //             conditions.And = newJson
    //             setCurentCondition(localConditionList)
    //             break
    //         }
    //         x += 1
    //     }
    // }

    function newAnd() {
        let newJson = { "select": "", "cond": "", "subs": "", "And": {} }
        if (curentCondition && curentCondition.And) {
            let localConditionList = curentCondition
            let x = 0
            for (let conditions = localConditionList; conditions.And; conditions = conditions.And) {//bug sur l'écriture du end (ou entre écriture et save)
                if (x === ifAnd) {
                    setIfAnd(cunrentIfAnd => cunrentIfAnd + 1)
                    let ifCompLoco = ""
                    if (ifCondition === "is not empty" || ifCondition === "else") {
                        ifCompLoco = ""
                    } else {
                        if (ifComparaison && ifComparaison.length > 0) {
                            ifCompLoco = ifComparaison
                        } else {
                            ifCompLoco = ifComparaisonTxt
                        }
                    }
                    conditions.select = ifCondition
                    conditions.cond = ifCompLoco
                    conditions.subs = ifResult
                    if (ifBlock !== null || ifBlock !== "") {
                        conditions.block = ifBlock
                    }
                    conditions.And = newJson
                    setCurentCondition(localConditionList)
                    break
                }
                x += 1
            }
        } else {
            setIfAnd(1)
            let ifCompLoco = ""
            if (ifCondition === "is not empty" || ifCondition === "else") {
                ifCompLoco = ""
            } else {
                if (ifComparaison && ifComparaison.length > 0) {
                    ifCompLoco = ifComparaison
                } else {
                    ifCompLoco = ifComparaisonTxt
                }
            }
            let outputJson = { "select": ifCondition, "cond": ifCompLoco, "subs": ifResult, "And": newJson }
            if (ifBlock !== null || ifBlock !== "") {
                outputJson.block = ifBlock
            }
            setCurentCondition(outputJson)
        }
        setIfComparaison([])
        setIfComparaisonTxt("")
        setIfCondition("")
        ifConditionCleanup("")
        setSelectedSubs([])
        setIfBlock("")
    }

    useEffect(() => {
        if (ifAnd >= 0) {
            let localCondition = curentCondition
            for (let condition = localCondition; condition.And; condition = condition.And) {
                condition.subs = ifResult
            }
            setCurentCondition(localCondition)
        }
    }, [ifResult])

    function moveAnd(shift = 0) {
        let localConditionList = curentCondition
        let x = 0
        for (let conditions = localConditionList; conditions.And || x === ifAnd; conditions = conditions.And) {
            if (x === ifAnd && ifCondition && (ifComparaison || ifComparaisonTxt)) {
                let ifCompLoco = ""
                if (ifCondition === "is not empty" || ifCondition === "else") {
                    ifCompLoco = ""
                } else {
                    if (ifComparaison && ifComparaison.length > 0) {
                        ifCompLoco = ifComparaison
                    } else {
                        ifCompLoco = ifComparaisonTxt
                    }
                }
                conditions.select = ifCondition //
                conditions.cond = ifCompLoco
                conditions.subs = ifResult
                if (ifBlock !== null || ifBlock !== "") {
                    conditions.block = ifBlock
                }
                setCurentCondition(localConditionList)
                break
            }
            x += 1
        }
        x = 0
        for (let conditions = localConditionList; conditions.And || x === (ifAnd + shift); conditions = conditions.And) {
            if (x === (ifAnd + shift)) {
                if (conditions.block) {
                    setIfBlock(conditions.block)
                } else {
                    setIfBlock("")
                }
                if (typeof conditions.cond === typeof []) {
                    setIfComparaison(conditions.cond)
                    setIfComparaisonTxt("")
                } else {
                    setIfComparaisonTxt(conditions.cond)
                    setIfComparaison([])
                }
                if (conditions.subs) {
                    setIfResult(conditions.subs)
                    setSelectedSubs(conditions.subs)
                } else {
                    setIfResult([])
                    setSelectedSubs([])
                }
                if (conditions.select) {
                    setIfCondition(conditions.select)
                } else {
                    setIfCondition("")
                }
                setNewRule(true)
                break
            }
            x += 1
        }
        setIfAnd(cunrentIfAnd => cunrentIfAnd + shift)
    }

    function removeAnd() {
        var localCondition = JSON.parse(JSON.stringify(curentCondition))
        if (ifAnd === 0) {
            localCondition = curentCondition.And
        } else {
            let x = 1
            for (let condition = localCondition; condition.And; condition = condition.And) {
                if (x === ifAnd) {
                    condition.And = condition.And.And
                    break
                }
                if (!condition.And || !condition.And.And) {
                    break
                }
                x += 1
            }
        }
        var shift = 0
        if (!localCondition.And || !Object.keys(localCondition.And).length || lenghtAnd(localCondition) === 1) {
            setCurentCondition({})
            setIfAnd(-1)
            setIfBlock(localCondition.block)
            if (typeof localCondition.cond === typeof []) {
                setIfComparaison(localCondition.cond)
                setIfComparaisonTxt("")
            } else {
                setIfComparaisonTxt(localCondition.cond)
                setIfComparaison([])
            }
            setIfResult(localCondition.subs)
            setSelectedSubs(localCondition.subs)
            setIfCondition(localCondition.select)
            setNewRule(true)
            return
        } else if (ifAnd !== 0) {
            shift = - 1
        }
        setCurentCondition(localCondition)
        var x = 0
        for (let conditions = localCondition; conditions.And || x === (ifAnd + shift); conditions = conditions.And) {
            if (x === (ifAnd + shift)) {
                setIfBlock(conditions.block)
                if (typeof conditions.cond === typeof []) {
                    setIfComparaison(conditions.cond)
                    setIfComparaisonTxt("")
                } else {
                    setIfComparaisonTxt(conditions.cond)
                    setIfComparaison([])
                }
                setIfResult(conditions.subs)
                setSelectedSubs(conditions.subs)
                setIfCondition(conditions.select)
                setNewRule(true)
                break
            }
            x += 1
        }
        setIfAnd(cunrentIfAnd => cunrentIfAnd + shift)
        //remove the curent ifAnd
        //if curentCondition dont have And, curentCondition = {}
        //always set to ifAnd - 1 if possible
    }

    function lenghtAnd(localCondition) {
        let x = 0
        for (let conditions = localCondition; conditions.And; conditions = conditions.And) {
            x += 1
        }
        return x
    }

    function ifDisplay(condition) {
        let cond = ""
        if (condition.block) {
            cond = (components[condition.block] && components[condition.block].data && components[condition.block].data.label) + " " + t(condition.select) + " " + (typeof condition.cond === typeof [] ? condition.cond.join(", ") : condition.cond)
        } else {
            let error = false
            condition?.subs?.forEach(sub => {
                if (!components[sub]?.data?.sub) {
                    error = true
                }
            })
            if (error) {
                return "Error: cancel to go back or save to erase"
            }
            cond = (condition.subs[0] ? components[components[condition.subs[0]]?.data?.sub]?.data?.label : option.content) + " " + t(condition.select) + " " + (typeof condition.cond === typeof [] ? condition.cond.join(", ") : condition.cond)
        }
        if (condition.And) {
            for (let c = condition.And; c.And; c = c.And) {
                if (c) {
                    cond = cond + " " + t("and") + " " + (components[c.block] && components[c.block].data && components[c.block].data.label) + " " + t(c.select) + " " + (typeof c.cond === typeof [] ? c.cond.join(", ") : c.cond)
                }
            }
        }
        return (t("if") + " " + cond + " " + t("then show") + " " + (condition?.subs?.every(e => e?.data !== null) && condition?.subs?.map(e => components[e]?.data?.label).join(", ")))
    }

    function pasteDetail() {
            navigator.clipboard.readText().then((clipText) => {
                try {
                    let jsonParsed = JSON.parse(clipText)
                    if (jsonParsed && typeof jsonParsed === "object") {
                        let objSaveState = {}
                        if (dataSaveState) {
                            objSaveState = JSON.parse(dataSaveState)
                        }
                        if (jsonParsed.fieldType === objSaveState.fieldType && ((jsonParsed.fieldType === "custom input" && jsonParsed.name === objSaveState.name) || (jsonParsed.fieldType !== "custom input" && !jsonParsed.optionsCustom))) {
                            setConditional(jsonParsed.conditional)
                            setReadonly(jsonParsed.readOnly)
                            setHidden(jsonParsed.hidden)
                            setRequired(jsonParsed.required)
                            setOptionsCustom(jsonParsed.optionsCustom)
                            if (jsonParsed.optionsCustom) {
                                setDisplayedOptions(jsonParsed.optionsCustomList)
                            } else {
                                setDisplayedOptions(null)
                            }
                            if (jsonParsed.name === objSaveState.name) {
                                setDetails(details => ({ ...details, name: jsonParsed.label, description: jsonParsed.description, tooltip: jsonParsed.tooltip }))
                            } else {
                                setDetails(details => ({ ...details, description: jsonParsed.description, tooltip: jsonParsed.tooltip }))
                            }
                            if (jsonParsed.conditionalList) {
                                setConditionList(jsonParsed.conditionalList)
                            } else {
                                setConditionList([])
                            }
                            setRefresh((refresh) => ((refresh + 1) % 5) + 1)
                            toast.success(t("Has been overwritten"))
                        } else {
                            toast.error(t("The copied object does not match the current object"))
                        }
                    } else {
                        toast.error(t("The copied object is not a valid object"))
                    }
                }
                catch (e) {
                    toast.error(t("The copied object is not a valid object"))
                }
            });
    }

    return (option && id && components[id]) && (components[id].data.fieldType === "custom input" ? //TODO: replace by switch case
    <Card noPadding>
        <div className="p-2 flex justify-between w-full border-b">
            <h2 className="text-base font-medium text-gray-900 text-lg truncate">{(option && option.data) ? option.data.name : option.content}</h2>
            {/* <div onClick={onCancel}>
                <XCircleIcon className="h-7 w-7 text-gray-300 cursor-pointer" aria-hidden="true" />
            </div> */}
        </div>
        <div className="p-2">
            {compError.length ? compError.map(error => <Banner text={error} description={"reload hubspot schema when done"}/> ) : <></>}
            {details && details.name &&
                <>
                    <div>{t("Label")}</div>
                    <Input type="text" defaultValue={details.name} onChange={e => setDetails(details => ({ ...details, name: e.target.value }))} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
                </>
            }
            <div className="flex flex-row justify-evenly w-full mt-2 ">
                <button onClick={onCancel} className="ml-1 p-2 w-fit rounded-md bg-red-400 text-white hover:bg-red-500" type="button">{t("Cancel")}</button>
                <button type="button" onClick={() => {setJsonDisplay(true)}} className="ml-1 p-2 w-fit rounded-md bg-slate-50 text-white hover:bg-slate-100 border border-slate-200"><CodeIcon className="w-5 h-5 text-slate-600"/></button>
                <button onClick={optionSave} disabled={newRule} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-600 disabled:bg-primary-300" type="button">{t("Save")}</button>
            </div>
            {(conditional && !firstCond) &&
                <div className="pt-2">
                    {conditionList && id && conditionList.map((c, cindex) => (
                        <div className="w-full flex flex-row border border-gray-300" key={cindex}>
                            <>
                                <button type="button" onClick={() => { loadCondition(cindex) }} id={cindex + "tooltip"} data-tooltip-id="DetailsTooltip" data-tooltip-content={ifDisplay(c)} className="border-r w-full border-gray-300 p-1 truncate hover:bg-primary hover:text-white">{t("if") + " " + (components[c.block] && components[c.block]?.data && components[c.block]?.data?.label) + " " + t(c.select) + " " + (typeof c.cond === typeof [] ? (c.cond.lenght > 1 ? "*multiple conditions*" : c.cond[0]) : c.cond) + ", " + t("then show") + " " + (c.subs.every(e => e?.data !== null) && c.subs.map(e => components[e]?.data?.label).join(", "))}</button>
                            </>
                            <button onClick={() => setConditionList(conditionList.filter((x, i) => i !== cindex))} type="button" className="w-8 h-8 ml-auto p-2 ml-3 hover:bg-primary hover:text-white"><TrashIcon /></button>
                        </div>
                    ))}
                    <Tooltip id="DetailsTooltip" effect="solid" place="left" variant="info" />
                    {!newRule ?
                        <button type="button" onClick={() => { setNewRule(true) }} className="p-2 ml-3 hover:text-primary">
                            {t("+ Add new rule")}
                        </button>
                        :
                        <div className="mt-2 flex flex-col">
                            <div className="truncate">{t("If")} {ifBlock && components[ifBlock]?.data?.label}</div>
                            <div className="flex w-full">
                                {ifAnd >= 0 && <button className={"disabled:opacity-25 enabled:hover:text-primary"} disabled={ifAnd === 0 ? true : false} onClick={() => moveAnd(-1)}><ChevronLeftIcon className="w-3 h-full max-h-16" preserveAspectRatio="none" /></button>}
                                <div className="w-full">
                                    <SimpleSelect setValue={setIfBlock} value={ifBlock} setOnHover={setResultHover} labels={Object.values(components).filter(e => e.data.fieldType !== "custom input" && e.data.sub !== id).map(x => x.data.label)} options={Object.values(components).filter(e => e.data.fieldType !== "custom input" && e.data.sub !== id).map(x => x.id)} />
                                    {ifBlock ?
                                        <SimpleSelect setValue={(e) => { setIfCondition(e); ifConditionCleanup(e) }} value={ifCondition} labels={Object.keys(conditionsLabels).includes(components[ifBlock]?.data?.fieldType) ? conditionsLabels[components[ifBlock].data.fieldType].concat(conditionList.length > 0 && "else") : conditionsLabels["misc"].concat(conditionList.length > 0 && "else")} />
                                        : <SimpleSelect disabled={true} value={""} className={"read-only"} labels={[]} />
                                    }
                                    {getComparaisonRend(components[ifBlock])}
                                </div>
                                {ifAnd >= 0 && (ifAnd >= lenghtAnd(curentCondition) - 1 ? <button className="hover:text-primary" onClick={newAnd}><PlusIcon className="w-3 h-full" /></button> : <button className="hover:text-primary" onClick={() => moveAnd(1)}><ChevronRightIcon className="w-3 h-full max-h-16" preserveAspectRatio="none" /></button>)}
                            </div>
                            {ifAnd >= 0 &&
                                <div className="flex h-2 w-full p-3">
                                    {[...Array(lenghtAnd(curentCondition)).keys()].map(e =>
                                        <button type="button" onClick={z => moveAnd(e - ifAnd)} className={"h-2 w-full mx-1 " + (e === ifAnd ? "bg-primary" : "bg-slate-300")} key={"blok-" + e}></button>
                                    )}
                                </div>
                            }
                            <div className="flex flex-row justify-between">
                                <div>{t("Then show:")}</div>
                                {ifAnd < 0 ?
                                    <button type="button" className="hover:text-primary" onClick={newAnd}>{t("+ And")}</button>
                                    :
                                    <button type="button" className="hover:text-primary" onClick={removeAnd}>{t("- Remove")}</button>
                                }
                            </div>
                            <MultipleSelect setOnHover={setResultHover} setValue={preIfResult} value={ifResult} multiple={true} labels={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.label)}
                                options={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.id)}
                            />
                            <div className="flex flex-row justify-evenly mt-2">
                                <button type="button" onClick={ifSave} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white px-4 hover:bg-primary-600">{t("Add (or)")}</button>
                                <button type="button" onClick={() => {
                                    ifCleanup();
                                    if (oldCondition !== null) {
                                        if (oldCondition.position !== null) {
                                            setConditionList(list => list.toSpliced(oldCondition.position, 0, oldCondition));
                                        } else {
                                            setConditionList(list => list.concat([oldCondition]));
                                        }
                                        setOldCondition(null)
                                    }
                                    setNewRule(false)
                                    setJustCanceld(true)
                                }} className="ml-1 p-2 w-fit rounded-md bg-slate-200 text-slate-600 px-4 hover:bg-slate-300">{t("Cancel")}</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        {jsonDisplay ? <JsonPopup json={components[id]?.data} title={components[id]?.data.name} cancel={() => {setJsonDisplay(false)}} save={pasteDetail}/> : <></>}
    </Card>
    :
    components[id].data.fieldType === "Variable" ?
    <Card noPadding>
        {/* //TODO: add custom */}
        <div className="p-2 flex justify-between w-full border-b">
            <h2 className="text-base font-medium text-gray-900 text-lg truncate">{(option && option.data) ? option.data.name : option.content}</h2>
            {/* <div onClick={onCancel}>
                <XCircleIcon className="h-7 w-7 text-gray-300 cursor-pointer" aria-hidden="true" />
            </div> */}
        </div>
        {/* <div className="p-2">
            {compError.length ? compError.map(error => <Banner text={error} description={"reload hubspot schema when done"}/> ) : <></>}
            {details && details.name &&
                <>
                    <div>{t("Label")}</div>
                    <Input type="text" defaultValue={details.name} onChange={e => setDetails(details => ({ ...details, name: e.target.value }))} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
                </>
            }
            <div className="flex flex-row justify-evenly w-full mt-2 ">
                <button onClick={onCancel} className="ml-1 p-2 w-fit rounded-md bg-red-400 text-white hover:bg-red-500" type="button">{t("Cancel")}</button>
                <button type="button" onClick={() => {setJsonDisplay(true)}} className="ml-1 p-2 w-fit rounded-md bg-slate-50 text-white hover:bg-slate-100 border border-slate-200"><CodeIcon className="w-5 h-5 text-slate-600"/></button>
                <button onClick={optionSave} disabled={newRule} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-600 disabled:bg-primary-300" type="button">{t("Save")}</button>
            </div>
            {(conditional && !firstCond) &&
                <div className="pt-2">
                    {conditionList && id && conditionList.map((c, cindex) => (
                        <div className="w-full flex flex-row border border-gray-300" key={cindex}>
                            <>
                                <button type="button" onClick={() => { loadCondition(cindex) }} id={cindex + "tooltip"} data-tooltip-id="DetailsTooltip" data-tooltip-content={ifDisplay(c)} className="border-r w-full border-gray-300 p-1 truncate hover:bg-primary hover:text-white">{t("if") + " " + (components[c.block] && components[c.block]?.data && components[c.block]?.data?.label) + " " + t(c.select) + " " + (typeof c.cond === typeof [] ? (c.cond.lenght > 1 ? "*multiple conditions*" : c.cond[0]) : c.cond) + ", " + t("then show") + " " + (c.subs.every(e => e?.data !== null) && c.subs.map(e => components[e]?.data?.label).join(", "))}</button>
                            </>
                            <button onClick={() => setConditionList(conditionList.filter((x, i) => i !== cindex))} type="button" className="w-8 h-8 ml-auto p-2 ml-3 hover:bg-primary hover:text-white"><TrashIcon /></button>
                        </div>
                    ))}
                    <Tooltip id="DetailsTooltip" effect="solid" place="left" variant="info" />
                    {!newRule ?
                        <button type="button" onClick={() => { setNewRule(true) }} className="p-2 ml-3 hover:text-primary">
                            {t("+ Add new rule")}
                        </button>
                        :
                        <div className="mt-2 flex flex-col">
                            <div className="truncate">{t("If")} {ifBlock && components[ifBlock]?.data?.label}</div>
                            <div className="flex w-full">
                                {ifAnd >= 0 && <button className={"disabled:opacity-25 enabled:hover:text-primary"} disabled={ifAnd === 0 ? true : false} onClick={() => moveAnd(-1)}><ChevronLeftIcon className="w-3 h-full max-h-16" preserveAspectRatio="none" /></button>}
                                <div className="w-full">
                                    <SimpleSelect setValue={setIfBlock} value={ifBlock} setOnHover={setResultHover} labels={Object.values(components).filter(e => e.data.fieldType !== "custom input" && e.data.sub !== id).map(x => x.data.label)} options={Object.values(components).filter(e => e.data.fieldType !== "custom input" && e.data.sub !== id).map(x => x.id)} />
                                    {ifBlock ?
                                        <SimpleSelect setValue={(e) => { setIfCondition(e); ifConditionCleanup(e) }} value={ifCondition} labels={Object.keys(conditionsLabels).includes(components[ifBlock]?.data?.fieldType) ? conditionsLabels[components[ifBlock].data.fieldType].concat(conditionList.length > 0 && "else") : conditionsLabels["misc"].concat(conditionList.length > 0 && "else")} />
                                        : <SimpleSelect disabled={true} value={""} className={"read-only"} labels={[]} />
                                    }
                                    {getComparaisonRend(components[ifBlock])}
                                </div>
                                {ifAnd >= 0 && (ifAnd >= lenghtAnd(curentCondition) - 1 ? <button className="hover:text-primary" onClick={newAnd}><PlusIcon className="w-3 h-full" /></button> : <button className="hover:text-primary" onClick={() => moveAnd(1)}><ChevronRightIcon className="w-3 h-full max-h-16" preserveAspectRatio="none" /></button>)}
                            </div>
                            {ifAnd >= 0 &&
                                <div className="flex h-2 w-full p-3">
                                    {[...Array(lenghtAnd(curentCondition)).keys()].map(e =>
                                        <button type="button" onClick={z => moveAnd(e - ifAnd)} className={"h-2 w-full mx-1 " + (e === ifAnd ? "bg-primary" : "bg-slate-300")} key={"blok-" + e}></button>
                                    )}
                                </div>
                            }
                            <div className="flex flex-row justify-between">
                                <div>{t("Then show:")}</div>
                                {ifAnd < 0 ?
                                    <button type="button" className="hover:text-primary" onClick={newAnd}>{t("+ And")}</button>
                                    :
                                    <button type="button" className="hover:text-primary" onClick={removeAnd}>{t("- Remove")}</button>
                                }
                            </div>
                            <MultipleSelect setOnHover={setResultHover} setValue={preIfResult} value={ifResult} multiple={true} labels={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.label)}
                                options={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.id)}
                            />
                            <div className="flex flex-row justify-evenly mt-2">
                                <button type="button" onClick={ifSave} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white px-4 hover:bg-primary-600">{t("Add (or)")}</button>
                                <button type="button" onClick={() => {
                                    ifCleanup();
                                    if (oldCondition !== null) {
                                        if (oldCondition.position !== null) {
                                            setConditionList(list => list.toSpliced(oldCondition.position, 0, oldCondition));
                                        } else {
                                            setConditionList(list => list.concat([oldCondition]));
                                        }
                                        setOldCondition(null)
                                    }
                                    setNewRule(false)
                                    setJustCanceld(true)
                                }} className="ml-1 p-2 w-fit rounded-md bg-slate-200 text-slate-600 px-4 hover:bg-slate-300">{t("Cancel")}</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div> */}
        {jsonDisplay ? <JsonPopup json={components[id]?.data} title={components[id]?.data.name} cancel={() => {setJsonDisplay(false)}} save={pasteDetail}/> : <></>}
    </Card>
        :
    <Card noPadding>
        <div className="p-2 flex justify-between w-full border-b">
            <h2 className="text-base font-medium text-gray-900 text-lg truncate">{(option && option.data) ? option.data.name : option.content}</h2>
            {/* <div onClick={onCancel}>
                <XCircleIcon className="h-7 w-7 text-gray-300 cursor-pointer" aria-hidden="true" />
            </div> */}
        </div>
        <div className="p-2">
            {!fromLogic &&
                <>
                    {getRender(details)}
                    {conditional !== undefined &&
                        <div className="flex flex-row justify-evenly mt-2">
                            <div>{t("Conditional")}</div>
                            <div className="grid grid-clos-1 place-items-center">
                                <Switch value={conditional} onClick={(a) => { setConditional(a) }}></Switch>
                            </div>
                            {/* <input type={"checkbox"} Value={option.data.conditional}  onClick={() => option.data.conditional = !option.data.conditional}></input> */}
                        </div>
                    }
                    <div className="flex flex-row justify-evenly mt-2">
                        <div>{t("Read Only")}</div>
                        <div className="grid grid-clos-1 place-items-center">
                            <Switch disabled={required || hidden} value={readonly} onClick={(a) => { setReadonly(!readonly); if(!readonly){setRequired(false)} }}></Switch>
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly mt-2">
                        <div>{t("Hidden")}</div>
                        <div className="grid grid-clos-1 place-items-center">
                            <Switch disabled={required} value={hidden} onClick={(a) => { setHidden(!hidden); if(!hidden){setRequired(false);setReadonly(true)} }}></Switch>
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly mt-2">
                        <div>{t("Required")}</div>
                        <div className="grid grid-clos-1 place-items-center">
                            <Switch disabled={readonly} value={required} onClick={(a) => { setRequired(!required); if(!required){setHidden(false);setReadonly(false)} }}></Switch>
                        </div>
                    </div>
                    {(option.data?.fieldType === "select" || option.data?.fieldType === "multi") &&
                        <div className="flex flex-row justify-evenly mt-2">
                            <div>{t("Options")}</div>
                            <div className="grid grid-clos-1 place-items-center">
                                <Switch value={optionsCustom} onClick={(a) => { setOptionsCustom(!optionsCustom) }}></Switch>
                            </div>
                        </div>}
                </>
            }
            {optionsCustom &&
                <MultipleSelect setValue={setDisplayedOptions} value={displayedOptions} labels={option.data.options.map(o => o.label)} options={option.data.options.map(o => o.value)} />
            }
            <div className="flex flex-row justify-evenly w-full mt-2 ">
                <button onClick={onCancel} className="ml-1 p-2 w-fit rounded-md bg-red-400 text-white hover:bg-red-500" type="button">{t("Cancel")}</button>
                <button type="button" onClick={() => {setJsonDisplay(true)}} className="ml-1 p-2 w-fit rounded-md bg-slate-50 text-white hover:bg-slate-100 border border-slate-200"><CodeIcon className="w-5 h-5 text-slate-600"/></button>
                <button onClick={optionSave} disabled={newRule} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-600 disabled:bg-primary-300" type="button">{t("Save")}</button>
            </div>
            {(conditional && !firstCond) &&
                <div className="pt-2">
                    {conditionList && id && conditionList.map((c, cindex) => (
                        <div className="w-full flex flex-row border border-gray-300" key={cindex}>
                            <>
                                <button type="button" onClick={() => { loadCondition(cindex) }} id={cindex + "tooltip"} data-tooltip-id="DetailsTooltip" data-tooltip-content={ifDisplay(c)} className="border-r w-full border-gray-300 p-1 truncate hover:bg-primary hover:text-white">{t("if") + " " + components[id].data.name + " " + t(c.select) + " " + (typeof c.cond === typeof [] ? (c.cond.lenght > 1 ? "*multiple conditions*" : c.cond[0]) : c.cond) + ", " + t("then show") + " " + (c?.subs?.every(e => e.data !== null) && c?.subs?.map(e => components[e]?.data?.label).join(", "))}</button>
                            </>
                            <button onClick={() => setConditionList(conditionList.filter((x, i) => i !== cindex))} type="button" className="w-8 h-8 ml-auto p-2 ml-3 hover:bg-primary hover:text-white"><TrashIcon /></button>
                        </div>
                    ))}
                    <Tooltip id="DetailsTooltip" effect="solid" place="left" variant="info" />
                    {!newRule ?
                        <button type="button" onClick={() => setNewRule(true)} className="p-2 ml-3 hover:text-primary">
                            {t("+ Add new rule")}
                        </button>
                        :
                        <div className="mt-2 flex flex-col">
                            <div>{t("If")} {option.data.label}</div>
                            <SimpleSelect setValue={(e) => { setIfCondition(e); ifConditionCleanup(e) }} value={ifCondition} labels={Object.keys(conditionsLabels).includes(option.data.fieldType) ? conditionsLabels[option.data.fieldType].concat(conditionList.length > 0 && "else") : conditionsLabels["misc"].concat(conditionList.length > 0 && "else")} />
                            {getComparaisonRend(option)}
                            <div className="flex flex-row justify-between">
                                <div>{t("Then show:")}</div>
                                {/* <button type="button" className="hover:text-primary">{t("+ And")}</button> */}
                            </div>
                            <MultipleSelect setOnHover={setResultHover} setValue={preIfResult} value={ifResult} multiple={true} labels={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.label)}
                                options={Object.values(components).filter(e => e.data.sub === id).map(x => x.data.id)}
                            />
                            <div className="flex flex-row justify-evenly mt-2">
                                <button type="button" onClick={ifSave} className="ml-1 p-2 w-fit rounded-md bg-primary-500 text-white px-4 hover:bg-primary-600">{t("Add (or)")}</button>
                                <button type="button" onClick={() => {
                                    ifCleanup();
                                    if (oldCondition !== null) {
                                        if (oldCondition.position !== null) {
                                            setConditionList(list => list.toSpliced(oldCondition.position, 0, { "select": oldCondition.select, "cond": oldCondition.cond, "subs": oldCondition.subs }));
                                        } else {
                                            setConditionList(list => list.concat([{ "select": oldCondition.select, "cond": oldCondition.cond, "subs": oldCondition.subs }]));
                                        }
                                        setOldCondition(null)
                                    }
                                    setNewRule(false)
                                    setJustCanceld(true)
                                }} className="ml-1 p-2 w-fit rounded-md bg-slate-200 text-slate-600 px-4 hover:bg-slate-300">{t("Cancel")}</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        {jsonDisplay ? <JsonPopup json={components[id]?.data} title={components[id]?.data.name} cancel={() => {setJsonDisplay(false)}} save={pasteDetail}/> : <></>}
    </Card>)
}

export default withTranslation()(Details);