import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { withTranslation } from 'react-i18next'
import getContentFiltered from '../../utils/functions/filteredData'

// const sortOptions = [
//     { name: 'Oldest', id: "old" },
//     { name: 'Newest', id: "new" },
// ]

function getSortedData(type, listCandidatures) {
    if (type === "old") {
        return listCandidatures.sort((a, b) => { if (a.modificationDate < b.modificationDate) { return -1 } return 1 }) // à sort avec la date
    } else {
        return listCandidatures.sort((a, b) => { if (a.modificationDate > b.modificationDate) { return -1 } return 1 })
    }
}

// function compareDates(a, b) {
//     if (a.modificationDate > b.modificationDate) {
//         return -1
//     } return 1
// }

function getFilteredData(filters, listCandidatures, search) {
    var cands = listCandidatures;
    filters.forEach(filter => {
        const values = filter?.options?.filter(opt => opt.actif)
        if (values && values.length) {
            cands = cands.filter(c => values.find(v => v.value === c[filter.id]));
        }
    });
    cands = getContentFiltered(cands, search);
    return cands
}

function FilterBarCandidature({ t, candidatures, setFilteredCandidatures, filterList }) {
    const [filters, setFilters] = useState(null);
    const [searchBarValue, setSearchBarValue] = useState("");
    const [sortType, setSortType] = useState(null);

    useEffect(() => {
        if (candidatures && candidatures.length) {
            var filtersContruction = [];
            filtersContruction = candidatures[0]?.Parameters?.filter(p => p.filter === true).map(p => { return { id: p.name, name: p.label } });
            filterList?.forEach(filter => {
                filtersContruction.push({
                    id: filter.name,
                    name: filter.label,
                    options: filter.options.map(o => { return { value: o.value, label: o.label, actif: false } }).filter(opt => candidatures.find(c => c[filter.name] === opt.value))                })
            })
            filtersContruction.push({
                id: "enabled",
                name: "Actif",
                options: [{ value: true, label: t("Actif"), actif: true }, { value: false, label: t("Non actif"), actif: false }]
            })
            filtersContruction = filtersContruction.filter(p => p?.options?.length > 1)
            setFilters(filtersContruction);
            setFilteredCandidatures(getSortedData(null, getFilteredData(filtersContruction, candidatures, searchBarValue)));
        }
    }, [candidatures, t, setFilteredCandidatures]);

    function handleOnChange(type, filter, search) {
        setSearchBarValue(search);
        setFilters(filter);
        setSortType(type);
        setFilteredCandidatures(getSortedData(type, getFilteredData(filter, candidatures, search)));
    }


    return filters && filters.length ? <div>
        <div className="flex my-4">
            <div className="flex-1 px-2 flex justify-center">
                <div className="max-w-xs w-full">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <input
                            onChange={(ev)=>{handleOnChange(sortType, filters, ev.target.value)}}
                            type="text"
                            id="search" name="search"
                            className="block w-full bg-light-gray-700 bg-opacity-50 py-1 2xl:py-2 pl-10 pr-3 shadow rounded-md leading-5 focus:text-gray-900 text-xs 2xl:text-sm" placeholder={t("Search")} />
                    </div>
                </div>
            </div>
        </div>
        <div className="flex items-center justify-between px-2 mb-4">
            {/* <Menu as="div" className="relative z-10 inline-block text-left">
                <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        {t("Sort")}
                        <ChevronDownIcon
                            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white">
                        <div className="py-1">
                            {sortOptions.map((option) => (
                                <Menu.Item key={option.id} onClick={() => handleOnChange(option.id, filters)}>
                                    {({ active }) => (
                                        <div
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm font-medium text-gray-900 cursor-pointer'
                                            )}
                                        >
                                            {t(option.name)}
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu> */}

            <Popover.Group className="flex items-baseline space-x-2 sm:space-x-8">
                {filters.map((section, sectionIdx) => (
                    <Popover
                        as="div"
                        key={section.name}
                        id={`desktop-menu-${sectionIdx}`}
                        className="origin-top-left relative z-10 inline-block text-left"
                    >
                        <div>
                            <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                <span>{section.name}</span>
                                {section?.options?.filter(opt => opt.actif).length > 0 ? (
                                    <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                        {section.options.filter(opt => opt.actif).length}
                                    </span>
                                ) : null}
                                <ChevronDownIcon
                                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel anchor="right" className="overflow-auto absolute max-h-[70vh] mt-2 bg-white rounded-md shadow-2xl p-4">
                                <form className="space-y-4">
                                    {section?.options?.map((option, optionIdx) => (
                                        <div key={option.value} className="flex items-center cursor-pointer">
                                            <input
                                                onChange={() => {
                                                    var filtersLocal = filters;
                                                    var filterLocal = filters[sectionIdx];
                                                    var optionLocal = filterLocal.options[optionIdx];
                                                    optionLocal.actif = !optionLocal.actif;
                                                    filterLocal.options[optionIdx] = optionLocal;
                                                    filtersLocal[sectionIdx] = filterLocal;
                                                    handleOnChange(sortType, filtersLocal, searchBarValue)
                                                }}
                                                id={`filter-${section.id}-${optionIdx}`}
                                                name={`${section.id}[]`}
                                                checked={option.actif}
                                                type="checkbox"
                                                className="h-4 w-4 border-gray-300 rounded text-indigo-600 cursor-pointer"
                                            />
                                            <label
                                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </form>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                ))}
            </Popover.Group>
        </div>
    </div> : null
}

export default withTranslation()(FilterBarCandidature);